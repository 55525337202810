/* Global Swiper Settings */
.swiper {
  width: 100%;
  height: 600px; /* Maintain square height */
  margin: 20px auto;
  display: flex;
  flex-direction: column;
}

/* Swiper Slide */
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width for all screen sizes */
  height: 600px; /* Square aspect ratio */
}

/* Image Styling */
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px; /* Optional rounded corners */
}

/* Text Overlay */
.c-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: end;
  top: 0;
  width: 100%;
  height: 200px;
  padding-bottom: 10px;
}

.c-text h1 {
  font-size: 25px !important;
  font-weight: bold !important;
  font-family: Arial, Helvetica, sans-serif !important;
  color:  #03452f;
}

.c-text h3 {
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: Arial, Helvetica, sans-serif !important;
  color: #944114;
}

/* Title Text */
.title-text {
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: Arial, Helvetica, sans-serif !important;
  color:  #03452f;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
}

/* Swiper Slide for next slide */
.swiper-slide-next {
  background-color: #c1a122;
}

/* Adjustments for Tablet (Responsive) */
@media screen and (max-width: 1024px) {
  .swiper-slide {
    height: 500px; /* Adjust height for laptops and tablets */
  }
  .swiper {
    height: 500px; /* Adjust height for tablets */
  }
}

/* Adjustments for Mobile (Responsive) */
@media screen and (max-width: 780px) {
  .swiper-slide {
    height: 450px; /* Adjust height for mobile */
  }
  .swiper {
    height: 450px; /* Adjust height for mobile */
  }
}
