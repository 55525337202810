@font-face {
  font-family: "pierosans";
  src: url(./font/IRANSansX-Medium.woff2) format(woff2);

}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

* {
  box-sizing: border-box !important;
  font-family: pierosans !important;
  
}

.light-mode {
  background-color: white;
  color: black;
}

.dark-mode {
  background-color: black;
  color: white;
}

body {
  background-color: rgb(239, 239, 240) !important;
  overflow-x: hidden !important;
  width: 100% !important;
  transition: background-color 0.3s, color 0.3s;
  margin: 0 !important;
  padding: 0 !important;
  
}

@font-face {
  font-family: myFont;
  src: url(./font/MMITRABDMRT2.ttf);
}

a {
  text-decoration: none !important;
  font-family: myFont !important;
}

h2,
h1,
h3,
p,
h4,
h5,
h6 {
  font-family: myfont !important;
}

body.rtl {
  direction: rtl;
}

body.ltr {
  direction: ltr;
}