
.swiper {
  width: 100%;
  height: 100vh;
  direction: rtl;
  padding: 50px; 
  box-sizing: border-box;
  margin-top: 120px !important;
}

/* Swiper Slide */
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

/* Image Styling */
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: fill !important;
  border-radius: 15px;
}

/* Semi-Transparent Overlay */
.swiper-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  z-index: 1;
}

/* Text Overlay Styling */
.slider-text {
  position: absolute;
  width: 50%;
  text-align: center;
  padding: 20px;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  color: white;
  z-index: 2;
}

/* Different text positions for variety */
.position-0 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position-1 {
  bottom: 10%;
  left: 10%;
  text-align: left;
  transform: none;
}

.position-2 {
  top: 20%;
  right: 15%;
  text-align: right;
  transform: none;
}

/* Title and Description Styling */
.slider-text h1 {
  font-size: 48px;
 
  font-weight: bold;
  text-transform: uppercase;
}

.slider-text p {
  font-size: 18px;

  line-height: 1.5;
  opacity: 0.9;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .slider-text {
    width: 80%;
    padding: 15px;
  }

  .slider-text h1 {
    font-size: 28px;
  }

  .slider-text p {
    font-size: 16px;
  }
}
